import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import Link from '../Components/Link'
import ColumnMargin from '../Components/ColumnMargin'
import neat from '../Images/neat.jpg'


const Benefits: React.FC = () => {
    return (
        <Background url={neat} image={true}>
            <Title content='The Benefits of Structural Integration and Why do this?' />
            <br />
            <br />
            <ColumnMargin>
                Structural Integration is an individual experience with numerous effects and benefits.  If your  body is aligned and balanced in gravity, it moves more gracefully and it creates more space in your joints and body in general. Movement is a lighter, breathing is easier and good posture is more effortless and doesn't have to be imposed.    Also, more efficient use of your muscles allows the body to conserve energy and creates more aware and economical patterns of movement. This can result in increased levels of energy and alertness. Feelings of stress can also decrease while your level of relaxation can increase.  Structural Integration tries to affect you as a whole, emotionally, and physically, by way of altering your past physical structure that your nervous system does not want to give up. Our emotional and behavioral reactions from our past relationships (in particular our emotional and physical environment which was given or imposed upon us or we chose to participate in) shaped our current physical and emotional self.  As these physical patterns are shown to you which are detrimental to your current "pain body" as Ekhart Tolle calls it, are removed and the body comes into greater alignment, qualities of the self may also become more aligned.   Experiences with Structural Integration may help release an individual's potential, promoting positive change, lowering anxiety, improving sleep, increasing confidence, decreasing pain and maturing emotional expression. Finally, the Structural Integration Anatomy Trains 12 series provides increased body awareness and sensitivity allowing you to feel a truly mobile and free body.  
                <br /><br />
                 Altered from <Link href='https://www.theiasi.net/the-benefits-of-structural-integration'>The Benefits of Structural Integration</Link>
                 <br /><br />

什麼是Structural Integration(Anatomy Trains-AT)
動作肌筋膜整合(AT)源於Ida P Rolf博士的先驅之作，隨後由Thomas Myers發揚光大，為一系列深層、和緩的筋膜與肌筋膜徒手治療（通常為12次）組成的療程，並結合身體動作的重新訓練。AT亦是培訓「結構整合」治療師資的學校之一。「結構整合」一詞係Ida Rolf為自己畢生心血所創之名稱，可謂以21世紀的方式理解身體結構運作的古老技藝。<br /><br />
AT「品牌」屬於結構整合的一種，著重以解剖學般之精準進行深層、持久、效果顯著的治療，並搭配動作與感觸引導個人體驗。AT結構整合的「作法」係根據「解剖列車肌筋膜經絡」的概念，在Thomas Myers所著書中有詳細介紹。該書於2001年首次由 Harcourt Brace (Elsevier)出版，2008年更新第二版。<br /><br />
AT設計宗旨為鬆解身體運動系統的勞損，重建身體的自然平衡、正位、長度與放鬆。常見的勞損源於無效率的動作習慣與身體對設計不良的汽車、桌椅、電話、飛機等的回應。個人勞損源於自小模仿的動作，受傷、手術、生產對身體的侵害，以及身體對創傷事件的回應。許多動作起初只是單純的回應性姿態，卻逐漸發展成神經肌肉的習慣。習慣性動作形成一個人的姿勢，姿勢需要結構改變，也就是身體結締組織「構造」的改變。換言之，姿態形成習慣，習慣形成姿勢，最終定型在身體結構裡。這些改變鮮少是好的。任何讓身體遠離正位的拉力都表示重力的作用，讓身體愈來愈遠離正位或增加了身體張力以抵抗重力，結果代償衍生出更多代償和症狀。AT的目的，就是藉由身體結締組織網絡獨特的性質來逆轉這個過程並降低結構壓力。<br /><br />
結締組織在生物學上的功能十分多樣化，負責支撐身體裡所有組織，包括液體的血液到固體的骨骼，還有介於二者之間各種片狀、帶狀、吊鉤狀的構造。肌肉組織讓身體移動，而每一個動作都是透過結締組織的筋膜、肌腱和連接骨頭的韌帶來完成。結締組織亦建構出身體的形狀。每當身體受傷或承壓，不論來源為何，都會產生神經肌肉反應，這些反應往往包括收縮、回縮、不活動，通常還有旋轉。但這些動作模式會讓部分肌肉處在勞損狀態（產生疼痛點），也會對筋膜構造形成拉力，使筋膜移位、增厚、與周遭結構沾黏，以對過度持久的肌肉支撐產生代償作用。<br /><br />
尤其針對慢性和維持已久的動作模式，光是鬆弛肌肉支撐還不夠，不過這絕對是好的開始。鬆解筋膜，讓筋膜歸位，並重新整合動作模式，讓身體輕鬆停留在適當的位置便是AT的作用。從此角度而言，AT可說與整骨和整脊療法相輔相成。但AT並非以蠻力將骨頭推回原位，而是調整筋膜「鋼索」，讓它們停留在原位，這種新正位便自然而然成為身體的一部分，不需要刻意努力或重複看治療師來維持。<br /><br />
作為結構整合的一種，KMI「品牌」與羅爾夫學院(Rolf Institute)、哈樂手療(Hellerwork)、結構整合公會(Guild for Structural Integration)提供的類似培訓都不同。<br /><br />
                <ul>
                    <li>AT不只是深層治療，而且使用了廣泛的觸摸「語彙」來喚起身體持久漸進的變化。</li>
                    <li>AT的「身體閱讀」法（視覺分析）具邏輯與連貫性，將技藝一步步推展開來。</li>
                    <li>AT療程的「作法」係根據「解剖列車肌筋膜經絡」的概念。</li>
                    <li>AT深入了解「組織裡的問題」（身體運輸與張力的情緒支撐），因此品牌標語是「改變身體對心靈的認知」。</li>
                </ul>
                <br />
                <br />
AT療程以身體肌筋膜相連現象為本，確保 1) 療程照顧到身體所有部位，2) 療程技巧清楚符合個人模式和偏好，3) 使用方法容易向其他醫療專業人士解釋。AT培訓與解剖列車週末課程一樣充滿「開放式探究」的氛圍，以「輕鬆密集」的方式進行。
</ColumnMargin>
            <br />
            <br />
        </Background>
    )
}


export default Benefits