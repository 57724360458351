import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import neat from '../Images/neat.jpg'
import Link from '../Components/Link'
import ColumnMargin from '../Components/ColumnMargin'

const AInfo: React.FC = () => {
    return (
        <Background url={neat} image={true}>
            <Title content='Other links about Structural Integration' />
            <br/><br/>
            <ColumnMargin>
            Introduction to Anatomy Trains
            Introduction to Anatomy Trains
            Tom Myers introduces Anatomy Trains -- the essential concepts behind this "map" of the fascial fabric ...
            
            <Link href='https://www.youtube.com/watch?v=T60vzafspaY'>Interview with Tom</Link>
            ANATOMY TRAINS - Tom Myers Full Interview
            Here is the full interview that I did with Fascia and Biomechanics specialist, Thomas Myers! TRAINING FOR HUMANS...
            Tom talks about Anatomy Trains
            Tom Myers: "Anatomy Trains" | Talks at Google
            Tom Myers: "Anatomy Trains" | Talks at Google
            Counteracting Your Digital Obsession - Unwinding your Neuromyofascial Web Long hours in chairs, in contact with ...
            
            <Link href='https://www.youtube.com/watch?v=XG1A-Ct69ug'>Why Does The Massage Hurt?</Link>
            <Link href='https://www.youtube.com/watch?v=_FtSP-tkSug&t=50s&ab_channel=somanaut'>The Fuzz Speech</Link>
            </ColumnMargin>
            {window.innerWidth > 900 ?

                <div style={{height: '1000px'}} />

                :

                ''
            }
        </Background>
    )
}


export default AInfo