import React from 'react'
import styled from 'styled-components'

const Row:React.FC = (props) => {
    return(
        <StyledRow>
            {props.children}
        </StyledRow>
    )
}

const StyledRow = styled.div`
display: flex;
flex-direction: row;
flex-flow: row;
width: 100%;
justify-content: space-around;
`

export default Row