import React from 'react'
import styled from 'styled-components'

type TitleProps = {
    content: string
}

const Title: React.FC<TitleProps> = (props) => {
    return (
    <StyledTitle>{props.content}</StyledTitle>
    )
}

const StyledTitle = styled.span`
font-size: 1.8em;
text-align: center;
color: black;
font-weight: 600;
margin-top: 5%;
margin-left: 5%;
margin-right: 5%;
`

export default Title