import React from 'react'
import styled from 'styled-components'

type LinkProps = {
    href: string
}

const Link:React.FC<LinkProps> = (props) => {

    return (
        <Linke href={props.href}>{props.children}</Linke>
    )
}

const Linke = styled.a`
margin-left: auto;
margin-right: auto;
border-radius: 6px;
color: blue;
display: inline-block;
&:hover {
    box-shadow: 0 0 5px 3px rgba(0,0,0,0.7);
}
`

export default Link