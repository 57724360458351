import React, { useState } from 'react'
import styled from 'styled-components'
import NavBar from './NavBar'

type BackgroundProps = {
    url: string,
    image: Boolean
}

const Background: React.FC<BackgroundProps> = (props) => {

    const [flipped, setFlipped] = useState(false)

    const flip = () => {
        setFlipped(!flipped)
    }

    return (
        <>
            {
                props.image === true &&
                <BG style={{ backgroundImage: `url(${props.url})` }}>
                    {
                        window.innerWidth > 900 ?
                            <>
                                <br />
                                <NavBar />
                            </>
                            :

                            !flipped ?
                                <HB onClick={flip}>&#x02261;</HB>
                                :
                                <Side>
                                    <HB onClick={flip}>&#x02261;</HB>
                                    <NavBar />
                                </Side>

                    }

                    {props.children}
                </BG>
            }
            {
                props.image === false &&
                <BG style={{ background: props.url }}>
                    {!flipped ?
                        <HB onClick={flip}>&#x02261;</HB>
                        :
                        <Side>
                            <HB onClick={flip}>&#x02261;</HB>
                            <NavBar />
                        </Side>
                    }
                    {props.children}
                </BG>
            }
        </>
    )
}

const BG = styled.div`
width: 100vw;
height: 100vh;
background-repeat: repeat;
display: flex;
flex-direction: column;
position: relative;
overflow-x: hidden;
`

const Side = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
position: absolute;
right: 0;
top: 0;
background-color: rgba(0,0,13, 0.6);
@media (min-width: 900px){
    width: 30vw;
    animation-name: navIn;
    animation-duration: 2s;
    animation-iteration-count: 1;
}
@media (max-width: 900px){
    width: 100vw;
}
`

const HB = styled.button`
width: 5vh;
height: 5vh;
background-color: rgba(0,0,13, 0.6);
color: white;
border: none;
position: absolute;
top: 2vh;
right: 2vw;
font-size: 2.5em;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
z-index: 10;
`
export default Background