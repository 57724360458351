import React from 'react'
//component imports
import Home from './Views/Home'
import AT from './Views/AT'
import Series12 from './Views/Series12'
import About from './Views/About'
import Benefits from './Views/Benefits'
import AInfo from './Views/AInfo'
import Contact from './Views/Contact'
import GettingMost from './Views/GettingMost'
//utility imports
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

const App: React.FC = () => {


  return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/Anatomy-Trains" exact>
            <AT />
          </Route>
          <Route path="/12Series" exact>
            <Series12 />
          </Route>
          <Route path="/About" exact>
            <About />
          </Route>
          <Route path="/Benefits" exact>
            <Benefits />
          </Route>
          <Route path="/Additional-Info" exact>
            <AInfo />
          </Route>
          <Route path="/Contact" exact>
            <Contact />
          </Route>
          <Route path="/Getting-The-Most" exact>
            <GettingMost />
          </Route>
        </Switch>
      </Router>
  )
}

export default App