import React, { useState } from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import Column from '../Components/Column'
import InnerTitle from '../Components/InnerTitle'
import Button from '../Components/Button'
import Link from '../Components/Link'
import neat from '../Images/neat.jpg'

const Contact: React.FC = () => {
    const [fale, setFale] = useState(false)

    const flip = () => {
        setFale(!fale)
    }
    return (
        <Background url={neat} image={!false}>
            <Title content='Contact me' />
            <br /><br /><br />
            <Column>
                <Link href="mailto:robdogle@yahoo.com">Send Email now</Link> <br /><br />
                <Button clicker={flip} content='View Contact Info' />
                {fale && <InnerTitle content='+886918494082' />}
                {fale && <InnerTitle content='robdogle@yahoo.com' />}
                {window.innerWidth > 900 ?

                    <div style={{ height: '700px' }} />

                    :

                    ''
                }
            </Column>
        </Background>
    )
}

export default Contact