import React from 'react'
import styled from 'styled-components'

const Center:React.FC = (props) => {
    return (
        <StyledCenter>
            {props.children}
        </StyledCenter>
    )
}

const StyledCenter = styled.div`
text-align: center;
`

export default Center;