import React from 'react'
import styled from 'styled-components'

type ButtonProps = {
    content: string,
    clicker: Function
}

const Button:React.FC<ButtonProps> = (props) => {
    return (
        <StyledButton onClick={() => props.clicker()}>
            {props.content}
        </StyledButton>
    )
}

const StyledButton = styled.button`
cursor: pointer;
color: #000; 
background-color: #f8f9fa;
border-color: #f8f9fa;
margin-top: 3px;
margin-bottom: 3px;
display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
box-shadow:
0 2.8px 2.2px rgba(0, 0, 0, 0.034),
0 6.7px 5.3px rgba(0, 0, 0, 0.048),
0 12.5px 10px rgba(0, 0, 0, 0.06),
0 22.3px 17.9px rgba(0, 0, 0, 0.072),
0 41.8px 33.4px rgba(0, 0, 0, 0.086),
0 100px 80px rgba(0, 0, 0, 0.12);
&:hover{
    background-color: rgba(100,100,200, 0.6);
    box-shadow:
0 2.8px 2.2px rgba(0, 0, 0, 0.068),
0 6.7px 5.3px rgba(0, 0, 0, 0.096),
0 12.5px 10px rgba(0, 0, 0, 0.12),
0 22.3px 17.9px rgba(0, 0, 0, 0.144),
0 41.8px 33.4px rgba(0, 0, 0, 0.172),
0 100px 80px rgba(0, 0, 0, 0.24);
}
`

export default Button