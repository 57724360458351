import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import InnerTitle from '../Components/InnerTitle'
// import Link from '../Components/Link'
import ColumnMargin from '../Components/ColumnMargin'
import hat from '../Images/withahat.jpg'
import nohat from '../Images/withoutahat.jpeg'
import Row from '../Components/Row'
import neat from '../Images/neat.jpg'
const About: React.FC = () => {
    return (
        <Background url={neat} image={true}>
            <Title content='About Me and why I am doing this work' />
            <br/>
            <br/>
            <ColumnMargin>
            I got into this work because I realized while starting out as a movement teacher many years ago that I didn't know squat about anatomy, how to help people dealing with pain or about how the body really moves. Coincidentally, a friend gave me the book Anatomy Trains and a ton of my questions were answered. On top of Anatomy Trains showing me how to move better, it has helped me not rely on the old symptom based medicine. I look at the body as a whole system trying to find the "criminals" instead of going after the victims. The 12 series has changed my life of being in pain to one of being able to take myself out of it when it does arise. I hope I can do the same for you.
            <br/><br/>
            <InnerTitle content='I am the one without a hat.' />
            <br/><br/>
            {window.innerWidth > 900 &&
             <Row>
             <img width='30%' src={hat} alt=""/>
             <img width='50%' src={nohat} alt=""/>
             </Row>
            }
            {window.innerWidth <= 900 &&
            <>
             <img src={hat} alt="Definitely not Rob."/>
             <img src={nohat} alt="Rob without a hat."/>
             </>
            }
           
            <br/><br/>
            </ColumnMargin>
        </Background>
    )
}

export default About