import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import InnerTitle from '../Components/InnerTitle'
import ColumnMargin from '../Components/ColumnMargin'
import neat from '../Images/neat.jpg'


const Series12: React.FC = () => {
    return (
        <Background url={neat} image={!false}>
            <Title content='What are the 12 series?' />
            <br/><br/>
            <ColumnMargin>
                <InnerTitle content='What to expect for the 12 series or just one session' />
                <br/><br/>
                Anatomy Trains (AT) is a wonderful ‘tonic’ for your posture and movement, but it is no panacea. Do not undertake AT without medical permission if indicated, or if suffering from a ‘hot’ (inflammatory) disease. AT can be remarkably effective for chronic pain patterns of a structural nature, but is not designed as a ‘curative’ for any disease, or as a ‘first aid’ remedy for recent injury. Check with your practitioner if you are unsure whether AT is contraindicated.
                <br/><br/>
                 You can certainly ‘try out’ a session of AT to see if it suits you, but the best, most permanent and progressive results are obtained by undertaking the whole series. You can do the 12-session series within a period of three months. Spacing the sessions more than once a week can be done, but too close together does not give your body time to absorb the information, whereas drawing the process out too long means you risk losing the momentum essential to the process.  
                 <br/><br/>
                 AT practitioners have different styles, so be sure your practitioner ‘fits’ with you, and feel free to bring any issues you have with the work up to your practitioner.  Typically, the AT process will begin with a fairly extensive interview about your history and current habits. Most AT sessions are done in underwear or a bathing suit, without draping. Your practitioner will usually want to observe you standing and walking before the sessions start, in order to assess your current structural patterns. Your practitioner may take photos in order to give you a visual sense of the ‘before’ and ‘after’, since there can be some fairly dramatic changes in your shape.  (And sometimes there will not be dramatic visual changes – judge your AT experience by how you feel rather than how it looks.)      
                 <br/><br/>
                 AT work is done on a massage table, or for certain moves on a stool or bench. The practitioner will use his fingers, hands, or arm to contact certain tissues, and then ask you to move in specific ways while he or she opens and repositions those tissues. The process of opening these tissues can involve some burning, like a yoga stretch or exercising some long unused muscles. The pain, if the sensation gets that far, should be short and bearable. Please converse with your practitioner to find the right level of depth for you that allows the maximum value for each session consistent with your comfort. The idea is to achieve a balanced body that is pain-free. You may have to feel some of the stored pain as it leaves your body, especially in traumatized areas. Traumatized tissue can also contain emotional pain. Although we are not trained psychologists, your AT practitioner has been trained to sensitively work with you around these issues as they relate to your body structure. Feel free to work with your practitioner again to find the right level of work for you.     
                <br/><br/>
                 Each AT session deals with a different fascial plane or set of relationships in the body, progressively working around the body, and from superficial to deep and back again. Your practitioner may not work where you are reporting the symptoms, as the patterns that feed that problem are body-wide. A whiplash, for instance, is a problem of the neck for some days, a problem of the whole spine within a few weeks, and is linked to a whole body pattern within a few months.   It is not unusual to have odd feelings – physical or emotional – between sessions. Please contact your practitioner if they are cause for concern. Often, old long-forgotten pains will resurface for a time – this is a positive sign that the process of unwinding is well underway. Your practitioner has a short pamphlet, “Getting the Most From Your KMI Sessions”, which can be helpful.  
                <br/><br/>
                View your AT series as a project, with a beginning, middle, and an end; not an on- going and endless therapy. The initial 4 sessions deal with the superficial layers, the middle 4 sessions with deeper structures, and the last sessions of the AT process integrate the two layers and bring it into everyday movement. Results will continue to accrue after you have finished your final session.   Clients often return once a twice a year for a ‘tune up’ session, to ease the effects of the slings and arrows of outrageous fortune. Others simply go on to some other maintenance routine such as yoga, Tai Chi, Feldenkrais, a workout routine – whatever is appropriate to them. Still others return periodically for a shorter series of sessions, advanced work designed to take the process deeper into your body and your experience.
                <br/><br/>
                <Title content='Anatomy Trains預期療效' />
                <br/>
                AT是身體姿勢與動作的極佳「補品」，但它不是萬靈丹。如果有任何適應症或患有「熱症（發炎）」，請勿在未經醫療許可下進行AT療程。對於源自身體結構問題的慢性疼痛，KMI可以提供絕佳療效，但無法用來「治癒」任何疾病，亦不可作為新傷的「急救」療法。若您不確定AT有哪些禁忌，請諮詢您的治療師。
                當然，您可以先「嘗試」一次AT治療判斷是否適合您，但仍須接受完整療程才能達到最佳、最永久且漸進式的療效。您可在一季內完成12次治療的療程或分在三季內完成。治療間隔時間太近會無法給予身體足夠時間吸收資訊，但間隔太久則可能喪失療程必要的動力。
                不同的AT治療師有不同風格，請確保您的治療師「適合」您，也歡迎您向治療師提出任何療程相關問題。
                AT療程一開始會進行廣泛深入的訪談，以了解您過去和目前習慣。多數AT療程均是穿著內衣褲或泳衣進行，不使用其他覆蓋物。您的治療師通常會在療程開始前先觀察您的站姿和走姿，以評估您目前的身體結構模式。他/她也可能會拍照，讓您比較視覺上「治療前」與「治療後」的差異，畢竟有時治療前後身體形狀會有相當大的差異。
                （有時看起來差異不大，建議您從AT體驗的感受而非外觀來判斷。）
                AT療程會在按摩床上進行，某些動作則會使用板凳或長椅。治療師會用指部、手部或臂部接觸某些組織，然後請您以特定方式做動作，他/她則同時鬆開或調整這些組織的位置。鬆開這些組織時可能會有不適感，就跟瑜伽伸展或運動某些長久未用的肌肉時一樣。即使感受強烈到疼痛的地步，也理當不會持續太久且在可以忍受的範圍。請與您的治療師溝通，找出最適合您的力度，讓您在舒適中體會每次治療的最大價值，目的是讓身體在無疼痛感的狀態下達到平衡。不過您或許會在某些長久疼痛離開身體時感到疼痛，在受過傷的部位會格外明顯。
                受過創傷的組織也可能有情緒疼痛。雖然我們並非受過訓練的心理師，但您的AT治療師會以訓練有素的專業素養悉心治療這些組織，畢竟這些組織亦與身體結構有關。請隨時與治療師討論適合您的治療力度。
                每次AT治療處理的筋膜平面或筋膜組均與身體有不同關係，然後會漸進到全身各部位，從表層到深層再回到表層。您的治療師不見得會治療您提及有症狀的地方，因為造成問題的動作模式是全身性的。例如揮鞭式創傷對頸部而言是幾天的問題，對脊柱而言是幾週的問題，對整個身體而言則需幾個月來調養。
                在每次治療之間身體或情緒有異常感並不奇怪，若您感到擔憂，請聯絡您的治療師。很久以前、已經被您遺忘的疼痛往往過一段時間後會再度出現，這代表鬆解正在發揮作用。歡迎您向治療師取閱「發揮AT最佳療效」手冊，對您會頗有助益。
                請視AT療程為一項有開始、過程、結束的專案，而非長期無止盡的治療。最初四次治療身體表層，中間四次治療較深層的結構，最後幾次則整合表層與深層，並將治療帶到日常動作中。效果在治療完全結束後仍會繼續累積。
                顧客通常每年還會回來一兩次接受「微調」治療，紓解生活壓力對身體造成的衝擊。有些人則採取其他適合自己的定期保養方法，例如瑜伽、太極、費登奎斯或運動鍛鍊。另有些人選擇定期回來接受時間較短的進階療程，專門治療身體更深處，深化您的AT體驗。
            </ColumnMargin>
            <br/><br/>
        </Background>
    )
}


export default Series12