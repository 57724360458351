import React from 'react'
import styled from 'styled-components'

interface HSpacerProps {
  size?: 'sm' | 'md' | 'lg'
}

const HSpacer: React.FC<HSpacerProps> = ({ size = 'md' }) => {
  
  let s: number
  switch (size) {
    case 'lg':
      s = 50
      break
    case 'sm':
      s = 10
      break
    case 'md':
    default:
      s = 25
  }
  
  return (
    <StyledHSpacer size={s} />
  )
}

interface StyledHSpacerProps {
  size: number,
}

const StyledHSpacer = styled.div<StyledHSpacerProps>`
  height: 100%;
  width: ${props => props.size}px;
`

export default HSpacer