import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

interface RouterProps {
    to: string,
    text: string,
}

const RouterLink: React.FC<RouterProps> = (props) => {

    return (
        <StyledSections to={props.to} exact>{props.text}</StyledSections>
    )
}

const StyledSections = styled(NavLink)`
text-decoration: none;
font-size: 1.2em;
font-weight: 600;
margin: auto;
&:hover {
    color: #b3b300;
  }
  @media (min-width: 900px){
    color: black;
}
@media (max-width: 900px){
    color: white;
}
`



export default RouterLink