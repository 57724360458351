import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import ColumnMargin from '../Components/ColumnMargin'
import neat from '../Images/neat.jpg'


const GettingMost: React.FC = () => {
    return (
        <Background url={neat} image={!false}>
            <Title content='Getting the Most from Your 12 Sessions' />
            <ColumnMargin>
            <br/><br/>
     Now that you are starting your Anatomy Trains sessions, here are some suggestions for making the most of the experience. The AT series is a project, a project of realigning your body, educating your kinesthetic sense, and reclaiming your whole bodily self from the many alienating influences we have in our culture. You may find that some of the following ideas are more to your liking, while others do not fit your situation – consider them all, and then take what you like.
     <br/><br/>
    -  It is recommended that you keep a journal during the process – you may be surprised at the changes as evidenced by your sensations, dreams, attitudes, or experiences. Start by standing in front of a mirror, and writing down everything that you can see, think of, or feel about your body. Be as honest as you can, noting areas you like, ones you do not, the areas that give you pleasure, and any chronic aches or pains. Think back to the things that have affected your body shape and body image – accidents and surgeries, incidents and imitations, your parents and your heroes. Try to make an entry just after and just before each of the sessions in the series, as well as any other time you are so moved.  
    <br/><br/>
   -  Leave time for a walk before and after your session – this will give you a chance to feel the ‘before’ and ‘after’ in your body, and in the familiar kinesthetic experience of walking. The walking will help you integrate the changes from the session as well, and give a chance for your head to clear before returning to the everyday absorption of driving and the rest of it. 
   <br/><br/>
    -  You could, for instance, simply park your car several blocks from the office.  During the session, the most benefit is derived from letting your practitioner in. It is common to react by tightening or pulling away. If your practitioner is creating too much sensation with their pressure or speed, let him or her know that they should lighten up, or slow down, or get out. While your practitioner knows what he or she is doing, you know you the best. This is your session, and the best results come with your ability to stay with the work and let go with it.  
    <br/><br/>
   -  People have different ways of opening up to the work. It is voluntary but subtle - bring your awareness to the part that is being worked, and accept the pressure. But if the pressure is so much that you have to mentally ‘leave’, then the work is too hard, and probably less productive.           The exception to this can be an area that was physically traumatized and is still storing a lot of pain. Here, the pain can be intense, but it is pain leaving your body. In any case, develop a dialogue with your practitioner about how they can best work with you.  
   <br/><br/>
    -  Between sessions, listen to your body. We are accustomed to dictating to our bodies from above. During the series is a time to listen for the messages coming up from below. Your body may want you to use it more – you may feel restless, for instance, so go with it: dance or do some stretching. Your body may give you signals of needing more rest as it repairs itself – go with that if you can.    
    <br/><br/>
   - Break up long periods of sitting with movement. Give yourself a few minutes break during every hour of driving, for instance, or do some stretching during long bouts with the computer. Long-held patterns of sitting can be very damaging to our structural integrity.  
   <br/><br/>
  -  A warm bath on the evenings after a session is often a treat, and adding Epson Salts (magnesium sulfate) to the bath will reduce any residual soreness.
  <br/><br/>
    -  You may notice odd stresses and strains in your body in the days following a session, as your body readjusts its tissues to the changing forces. This is normal. These feelings or pains should be passing – your practitioner will want to know about anything that lasts more than few days, or anything severe or worrying.  
    <br/><br/>
    -  Likewise, you may feel odd emotions between sessions, coming seemingly from nowhere. This is normal and these too should pass. As much as you can, just accept and watch them happen. Again, anything lasting should be reported to your practitioner.
    <br/><br/>
   -  Avoid imposing an ideal on your body – holding yourself into what you think of as a ‘proper’ posture. The sessions are designed to bring you toward a better balance without effort. Pinning your shoulders back or tucking your tail under to where you think it ‘should’ be will only get in the way. Your body has its own wisdom - allow it to unwind toward its own natural length and balance.     In the end, this may conform to some plumb line or grid, but it very well may not. To thine own self be true.
   <br/><br/>
   -  Your AT practitioner may give you exercises or awareness designed to help overcome old habits between sessions and bring in the new, but this awareness should be tried on gently and frequently, not imposed or held for long periods of time. Holding someone’s (even your own) idea of ‘good’ posture will only substitute a new set of strains for the old ones you left behind.  
   <br/><br/>
     -  Many times, the most helpful thing that the client can do is to notice when old patterns of holding are creeping back in and drop them. Noticing them as they reassert themselves is easier after they have been released in the sessions, but they do have a way of creeping back in. Your job as a client is to notice these holding patterns and just let them go, as often as necessary. After a period of watching the tendency come and go, it will simply relent and the new position will be your ‘natural’ place. Remaining gently sensitive to what your body is doing is your chief responsibility in this process.  
     <br/><br/>
    - Your body will also give you messages about your environment. Your way of standing over the sink, or, as mentioned, sitting at your desk may need to change to be consonant with your ‘new’ body. Your practitioner can help you with these adjustments, but you can also stay alert to when you are using yourself in way that no longer works.
    <br/><br/>
      - You can expect that the novelty of the changes you feel right after the session will feel less pronounced toward the end of the week. They are still there – it is just that your body has become more used to them and integrated them. The AT series is carefully worked out in a sequence that is designed to prevent you from regressing. In fact, you can expect positive changes to keep developing in the months following the end of the series.  
      <br/><br/>
     - Finally, enjoy yourself! The AT series is a wonderful way to explore your body posture and movement. It was so important and positive for your practitioner that he or she took it up as a career! We look forward to sharing this voyage of discovery, healing and ease with you.
     <br/><br/>

   發揮AT最佳療效
您已開始AT療程，這裡提供幾項建議讓您的療程發揮最大效果。AT療程是一項專案，它能讓身體回歸正位、鍛鍊動覺、重新喚回受到社侌文化裡諸多影響而偏離正位的身體。以下列出幾項建議，您可能偏好其中幾項，其他的可能不適合您。歡迎您都列入考慮，再選擇自己喜歡的。
<br/><br/>
<br/><br/>
 

建議您在療程期間寫下紀錄，您或許會驚訝地發現您的感受、夢境、態度、體驗有多大改變。首先站在鏡子前，寫下您看到的、想到的、對身體的一切感受，儘量對自己坦承，註明您喜歡的部位和不喜歡的部位，讓您感到愉悅的地方和有慢性疼痛的地方。接著回想哪些事情影響了您身體的形狀和形象，可能是意外事件、手術、不愉快的、模仿的、父母或心中的英雄。試著在每次治療前後或任何讓您感動至深的時刻寫下紀錄。
<br/><br/>
 

每次治療前後花點時間散步，讓自己有機會感受身體「治療前」和「治療後」的差異，以及您很熟悉的行走時的動覺體驗。散步亦有助於整合治療引起的變化，讓您在回歸日常瑣事之前讓頭腦清醒一下。例如您可以將車子停在離辦公室幾個街區之外，再散步到辦公室。
<br/><br/>
 

欲從療程獲得最大助益，最重要的就是信任您的治療師。很多人的反應是全身僵硬或把治療師推開。如果您的治療師在力道和速度上讓您感到不適，告訴他/她輕一點或慢一點，或請他/她離開。雖然治療師都受過精良訓練，但只有您最了解自己。這是您的療程，唯有順應療程並放鬆身體才能獲得最佳效果。
<br/><br/>
 

每個人對療程的接受方式不同，建議您將注意力放在接受治療的部位並接受治療師的力道。您可自行選擇要不要這麼做，但效果很微妙。若力道太大讓您必須將注意力「移開」，就表示治療力道太強，效果可能沒那麼好。唯一的例外是之前受過創傷的部位還遺留許多疼痛，這裡的疼痛感可能會很強烈，但這是疼痛正在離開身體造成的。請隨時與治療師溝通，讓他/她知道最適合您的治療方法。
<br/><br/>
 

在每次治療之間傾聽身體的聲音。我們都習慣於命令身體做事，但這段療程是讓您傾體身體發出訊息的好時機。您的身體可能希望您多使用它一點，讓您感到休息不足，但請順從它，跳跳舞或做些伸展。如果您的身體釋放出需要更多休息自我修復的訊號，請儘量照做。
<br/><br/>
 

久坐期間不時做些動作，例如長時間駕車可以每小時休息幾分鐘，或長時間使用電腦時做些伸展動作。長時間持續坐著對身體結構的完整性傷害極大。
<br/><br/>
 

治療過後在傍晚泡個溫暖的澡是一大享受，在水裡加點浴鹽（硫酸鎂）有助於消除剩餘的痠痛。
<br/><br/>
 

治療完隔天您或許會注意到身體有奇怪的壓力和勞損，那是因為身體正重新調整組織以適應改變的力道，十分正常。這種感覺或疼痛會自行消退，若持續數天不退或有任何嚴重到令您擔憂的狀況，請告訴您的治療師。
<br/><br/>
 

您或許也會在每次治療之間沒來由地出現奇怪的情緒感受，這很正常，也會自行消退。請試著儘量接受它並感受它消退，有任何持續不退的狀況請告知治療師。
<br/><br/>
 

避免強迫身體達到某種理想，刻意擺出自認為「適當」的姿勢。療程設計是為了讓您毫不費力達到更好平衡，刻意挺胸收腹到您認為「應該」的程度只會有害無益。您的身體自有主張，讓它自己鬆解到最自然的長度和平衡，最終身體可能會對齊某種鉛錘線或網格線，但也極可能不會。讓身體做真實的自己。
<br/><br/>
 

您的AT治療師可能會在每次治療之間要求您做某些練習或覺察，以克服舊習慣，養成新習慣，請輕柔、多次嘗試這種覺察，不要刻意為之或保持長時間固定不動。維持別人（甚至您自己）認為的「良好」姿勢只會對身體產生新的勞損。
<br/><br/>
 

許多時候您能做的最有用的事，是注意到是否舊有動作模式又悄悄出現，並有意識地屏棄它們。舊習在療程中獲得鬆弛後再度出現會比較容易引起您注意，但它們確實會不知不覺浮現。您要做的就是注意到舊習出現，然後儘量在需要時屏棄它們。這些舊習反覆出現又消失一陣子後就會自行減弱，新姿位就會成為您最「自然」的狀態。療程中持續溫柔地關注自己的身體是您最主要的責任。
<br/><br/>
 

您的身體亦會告知您關於環境的訊息。您站在水槽邊的姿勢或坐在桌前的姿勢或許都必須改變才能順應「新」的身體。您的治療師可協助您調整，但您也可自己留意過去使用身體的方式是否已不適用。
<br/><br/>
 

您可以預期療程結束後感到新奇的變化大約一週後便已不再明顯，這些變化仍在，只是您的身體變得比較習慣，也已經接納了它們。AT療程經過精心安排，療效不會倒退，甚至在療程結束後數個月還會持續出現正向改變。
<br/><br/>
 

最後，好好享受吧！AT療程是一段讓您探索身體姿勢與動作的美好旅程，正因為它對您的治療師帶來重大正面助益，他/她才會成為治療師。我們期待與您共同踏上這一趟發現、療癒、放鬆之旅！
</ColumnMargin>
<br/><br/>
<br/><br/>
        </Background>
    )
}


export default GettingMost