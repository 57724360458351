import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import InnerTitle from '../Components/InnerTitle'
import Link from '../Components/Link'
import ColumnMargin from '../Components/ColumnMargin'
import HSpacer from '../Components/HSpacer'
import neat from '../Images/neat.jpg'
import Center from '../Components/Center'

const Home: React.FC = () => {
    return (
        <Background url={neat} image={true}>
            <Title content='無為Anatomy Trains Structural Integration' />
            <ColumnMargin>
                <br /><br />
Wu wei, according to Lao Tzu, was the attainment of a purely natural way of behaving, as when the planets revolve around the sun. The planets effortlessly do this revolving without any sort of control, force, or attempt to revolve themselves, thus engaging in effortless and spontaneous movement. My hope is that I find that for you, similar to what structural integration has done for me.<br /><br />

<InnerTitle content='What is Structural Integration?'/><br /><br />

     Structural Integration is a type of bodywork that focuses on the connective tissue, or fascia, of the body. Fascia surrounds muscles, groups of muscles, blood vessels, organs, and nerves,lymph and cells binding some structures together while permitting others to slide smoothly over each other.<br /><br />

     Fascia is designed to be elastic and move freely with muscles and bones.  Injury, stress, work-related repetitive movements and the effects of aging can cause fascia to lose its elasticity and become shorter, tighter, and more dense. Tightened fascia pulls our muscles and skeleton out of proper alignment and posture, which can cause pain, discomfort, and fatigue.
     <br /><br />
       Structural Integration works to lengthen, stretch, and soften this tissue to restore postural balance, ease of movement, and a feeling of being more at home in your own body. It is practiced in an organized series of sessions or individual sessions within a framework that is designed to restore postural balance by aligning and integrating the body in gravity. Structural Integration is based on the work of Dr. Ida P. Rolf and my teacher Thomas Myers.  I practice it in accordance with the standards established by the International Association of Structural Integrators (IASI) and my teachers at the Anatomy Trains school in Maine, America.<br /><br />
       <Center>Altered from </Center>
<Center><Link href='https://www.theiasi.net/new-to-structural-integration-'>New to Structural Integration?</Link></Center>
<br /><br />
<Center>Further information about Thomas Myers and Dr. Rolf and the origins of Structural Integration are below.</Center>
 
 <br /><br />
 <span style={{textAlign: 'center'}}>
<Link href='https://www.anatomytrains.com/about-us/about-tom-myers/'>About Tom Myers</Link>   <HSpacer size='sm' />  <Link href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3162380/'>Structural Integration: Origins and Development</Link>
</span>
<br /><br />
<br /><br />
</ColumnMargin>
        </Background>
    )
}


export default Home