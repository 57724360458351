import React from 'react'


import Background from '../Components/Background'
import Title from '../Components/Title'
import Link from '../Components/Link'
import ColumnMargin from '../Components/ColumnMargin'
import neat from '../Images/neat.jpg'

const AT: React.FC = () => {
    return (
        <Background url={neat} image={true}>
  <Title content='What Makes Anatomy Trains Different?' />
  <br /><br />
  <ColumnMargin>
    Anatomy Trains is a form of Structural Integration (SI). The other brands of SI - Rolfing®, GSI, Hellerwork, Soma, Core, etc - are all good schools with talented practitioners - so this is not meant as a put down to anyone or any school. But each school / brand has a distinctive “flavor”, so the purpose of the next few paragraphs is to give a sense of the distinctive elements of Anatomy Trains.
    <br /><br />
    <ul>
      <li>AT is system-oriented, not symptom oriented</li>
      <li>AT work is applied gently and sensitively, with full client participation</li>
      <li>The AT series unfolds around a logical and coherent map of the myofasciae— the Anatomy Trains Myofascial Meridians</li>
      <li>AT practitioners welcome and value other inputs</li>
      <li>AT seeks client autonomy </li>
    </ul>
    <br /><br />
    Although many people come to AT and Structural Integration because of some kind of pain or restriction, the intent of this work - especially when undertaken as a series of sessions, is to get to the condition behind the immediate problem. Many shoulder injuries, for instance, are caused by the lack of support from the rib cage. What's the point of fixing the immediate problem if you don't at the same time moderate the cause?
    <br /><br />
    The AT series is designed to progressively build support, sturdiness and balance throughout the structural system, so that there is a whole new ‘frame’ underlying your posture and movement, which can keep old injuries from coming back and help to prevent new ones from happening. Ida Rolf used to joke: “If your symptoms get better, that's your tough luck”. An AT practitioner may or may not address right away the area where you are having pain or restrictions - the roots often lie at some distance from the presenting problem. It may take several sessions or most of the series to get to the specifics of the problem, since we have to build up the supporting structure first.
    <br /><br />
    In this way, AT more resembles classical acupuncture or homeopathy or osteopathy - where the symptoms were secondary and building the ‘constitution’ is primary.
    <br /><br />
    AT work is not imposed on the client. The work should be on this side of the pain threshold, and the client and practitioner work out where the pressure and intensity level should be for maximum benefit. No good will come of ‘grinning and bearing it’ through an entire series. Occasionally, it is beneficial to ‘expose’ pain stored in the body, but ‘imposing pain’ is not a part of AT work.
    <br /><br />
    Secondly, the client moves during the application of the manual therapy. If you are lying passively on the table for most of the session, you are not getting the best work you can get. AT follows Ida Rolf's pithy dictum: “Put it where it belongs and call for movement.” Your movement produces several benefits: it lessens the sensation by spreading it out, it engages your proprioception (inner sensing) that helps integrate the work, and it also helps the practitioner stay on the right layer of fascia during the release. Thirdly, your AT practitioner wants to hear about how the process is affecting you - physically, emotionally, in your exercise or other performance activities. Letting your practitioner know what's up is very helpful in getting the best work for you.
    <br/><br/>
    The AT session series is built around a logical, coherent approach to the myofascial system. The Anatomy Trains Myofascial Meridians provide a way to define the territory of each session, allowing the practitioner to be both confident that they are getting the proper territory, but also creative within each session to ensure that each individual client is getting the exact work that his or her unique pattern requires. There's more information than you would ever want to know about the Anatomy Trains on the rest of this site. <Link href='www.anatomytrains.com'>www.anatomytrains.com</Link>
    <br/><br/>
    The AT school, and AT practitioners, welcome other therapeutic inputs to your process. AT is compatible with osteopathy, chiropractic, cranial work, and most forms of massage, yoga, and exercise. We recognize that no way is the ‘one true way’ in manual and movement therapy, and we are always learning from other disciplines. Sometimes we absorb what we learn into our work, sometimes what we learn tells us when to refer to the other competent professionals within the wide spectrum of healing available.
    <br/><br/>
    The AT series is a project, with a beginning, a middle, and an end. The idea is to create the conditions where the client is independent and stable on their own, not to create a long-term dependency on the therapist or continued work. Of course people come back for more work, but not on a continuous basis. This work does its best in short, intense periods of work, followed by longer periods of absorption. We want you, within a reasonable period of time, not to need us any more.
  </ColumnMargin>
  <br /><br />
</Background>
    )
}


export default AT