import React from 'react'
import styled from 'styled-components'

const Column:React.FC = (props) => {
    return(
        <StyledColumn>
            {props.children}
        </StyledColumn>
    )
}

const StyledColumn = styled.div`
display: flex;
flex-direction: column;
flex-flow: column;
width: 100%;
justify-content: center;
align-items: center;
`

export default Column