import React from 'react'
import styled from 'styled-components'

const ColumnMargin:React.FC = (props) => {
    return (
    <Marginalized>{props.children}</Marginalized>
    )
}
const Marginalized = styled.section`
display: flex;
flex-direction: column;
width: 90%;
margin-left: 5%;
margin-right: 5%;
text-align: left;
line-height: 1.5em;
`
export default ColumnMargin